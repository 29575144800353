/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { changeColorOnVisible } from 'hooks/changeColorOnScroll';
import ReactVisibilitySensor from 'react-visibility-sensor';
import MovingPicture from 'components/MovingPicture';
import { useDispatch } from 'react-redux';

const EventsSection = () => {
  const { t } = useTranslation();
  const [isSlideVisible, setIsSlideVisible] = useState(false);
  const [isSlideSeen, setIsSlideSeen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    changeColorOnVisible('#a9a0dc', isSlideVisible, dispatch);
    if (!isSlideSeen && isSlideVisible) setIsSlideSeen(true);
    // eslint-disable-next-line
  }, [isSlideVisible]);

  const title = 'EVENTS';

  return (
    <div id="events" className="container  min-vh-100">
      <div className="row mx-0 h-100 min-vh-100">
        <div className="col-md-5 col-12 justify-content-center d-flex flex-column ">
          <ReactVisibilitySensor onChange={setIsSlideVisible} partialVisibility>
            {() => (
              <div className="d-flex mb-5 mx-auto ">
                <span className="display-1 link--ilin " style={{ color: '#fff' }}>
                  <span>{title.substring(0, title.length / 2)}</span>
                  <span>{title.substring(title.length / 2)}</span>
                </span>
              </div>
            )}
          </ReactVisibilitySensor>
          <div className="d-md-block d-none">
            <div className="letter-effect text-reveal-smallest text-white font-weight-normal text-reveal ">
              {t('service.events.1')}
            </div>
            <div className="letter-effect text-reveal-smallest text-white font-weight-normal text-reveal">
              {t('service.events.2')}
            </div>
          </div>
        </div>
        <MovingPicture
          maxHeight="120%"
          className="mb-3 position-relative d-flex align-items-center justify-content-center col-md-7 col-12"
          layer1="assets/images/digital/events-layer-1.webp"
          layer2="assets/images/digital/events-layer-2.webp"
          layer3="assets/images/digital/events-layer-3.webp"
        />
        <div className="d-md-none  d-block">
          <div className="letter-effect font-size-3 text-white font-weight-normal text-reveal ">
            {t('service.events.1')}
          </div>
          <div className="letter-effect font-size-3 text-white font-weight-normal text-reveal">
            {t('service.events.2')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsSection;
